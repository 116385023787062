import React,{useState,useEffect} from 'react'

import moment from 'moment'
import { Modal,Layout,Card,Button,Row,Col,} from 'antd';
import { PlusOutlined  } from '@ant-design/icons';


import Buscar from '../Component/Loading';


import ListaSolicitudes from '../Component/Mantenimiento/ListaSolicitudes';
import ModalMantenimiento from '../Component/Mantenimiento/ModalMantenimiento';
import ModalMenu from '../Component/Mantenimiento/ModalMenu';

import {useCrearMantenimiento,useGetAvisosMant} from '../hooks/custom-Mantenimiento';
import {useAllAviso,useAllRepercusiones,useAllUbicaciones} from '../hooks/custom-general';

const { Header } = Layout;
const Mantenimiento = (locale) => {
    
    const {data,loading} = useGetAvisosMant();
    const {ClaseAvisoAlldata,isClaseAvisoLoading} = useAllAviso();
    const {RepercusionesAlldata,isRepercusionesLoading} = useAllRepercusiones();
    const {UbicacionesAlldata,isubicacionesLoading} = useAllUbicaciones();
    const [SetCreate] = useCrearMantenimiento();
    
    const [mantenimientodata, setMantenimientodata] = useState([]);

    const [Loading, setLoading] = useState(false);
    const [OpenMant, setOpenMant] = useState(false);
    const [OpenMenu, setOpenMenu] = useState(false);
    
    const [Repercusiones, setRepercusiones] = useState([]);
    const [ClaseAviso, setClaseAviso] = useState([]);
    const [Ubicaciones,  setUbicaciones] = useState([]);
  
   
   
   useEffect(() => {
        if(loading===false){
            
            const datamantenimiento = data.GetAvisosMant
            setMantenimientodata(datamantenimiento)
        }

        if(isRepercusionesLoading===false){
          
            const Repercusiones = RepercusionesAlldata
            setRepercusiones(Repercusiones)
        }

        if(isClaseAvisoLoading===false){
           
            const ClaseAviso = ClaseAvisoAlldata
            setClaseAviso(ClaseAviso)
        }

         if(isubicacionesLoading===false){
            const ubicaciones = UbicacionesAlldata
            setUbicaciones(ubicaciones)
        }

        

         
    }, [ClaseAvisoAlldata, RepercusionesAlldata, UbicacionesAlldata, data, isClaseAvisoLoading, isRepercusionesLoading, isubicacionesLoading, loading])
   
    const handleSave = async (values) => {

        let descRepercusiones ="";
        Repercusiones.filter(subitem => subitem.codigo === values.Repercusiones).map(filtered => (  
            descRepercusiones = filtered.descripcion   
        ))

         let descClaseAviso = "";
       ClaseAviso.filter(subitem => subitem.codigo ===  values.ClaseAviso).map(filtered => (  
            descClaseAviso = filtered.descripcion   
        ))

        let descUbicaciones ="";
        Ubicaciones.filter(subitem => subitem.codigo === values.UbicacionTecnica).map(filtered => (  
            descUbicaciones = filtered.descripcion   
        ))
        
        let registros = parseInt(mantenimientodata[0].id_AvisoMantenimiento,10) + 1; 

        let array={
                id_AvisoMantenimiento:registros,
                fecha_falla : moment(values.FechaFalla).format('DD/MM/YYYY')+' '+moment(values.HoraFalla).format('HH:mm:ss'),
                clase_aviso : values.ClaseAviso,
                descrip_clase_aviso:descClaseAviso,
                descripaviso : values.DescripcionAviso,
                Ubicaciontecnica : values.UbicacionTecnica,
                descrip_Ubicaciontecnica:descUbicaciones,
                detalleaviso : values.DetalleAviso,
                supervisorasignado : values.Supervisor,
                equipoparado : values.EquipoDetenido.toString(),
                repercucion :  values.Repercusiones,
                descripRepercucion:descRepercusiones,
                estado:"Pendiente",
                color:"gold",
                __typename: "returnTAvisoType"
            }
            
         setMantenimientodata([array,...mantenimientodata ])
     
        const mensajeop='Enviado correctamente'
        const  modal = Modal.success({
                    content: mensajeop,
                });

                setTimeout(() => {
                    setLoading(false);
                    modal.destroy();
                 }, 3000);



        await  SetCreate({variables:{
                fecha_falla : moment(values.FechaFalla).format('DD/MM/YYYY'),
                hora_falla : moment(values.HoraFalla).format('HH:mm:ss'),
                clase_aviso : values.ClaseAviso,
                descripaviso : values.DescripcionAviso,
                Ubicaciontecnica : values.UbicacionTecnica,
                detalleaviso : values.DetalleAviso,
                supervisorasignado : values.Supervisor,
                equipoparado : values.EquipoDetenido.toString(),
                repercucion :  values.Repercusiones,
            }
        })
        
       
       
    };

    const showDrawer = () => {
        setOpenMant(true);
    };

    const onClose = () => {
        setOpenMant(false);
    };

    const onCloseMenu = () => {
        setOpenMenu(false);
    };

     const onOpenMenu = () => {
        setOpenMenu(true);
    };

     const Icon = ({ type, ...rest }) => {
        const icons = require(`@ant-design/icons`);
        const Component = icons[type];
        return <Component {...rest} />;
    };
    
    if(loading){
        return (
          <Buscar/> 
        )
    }


    return (
        
         <div >
       
            <Header  style={{backgroundColor: "white",padding: '15px 56px', 'borderBottom':'1px solid rgba(140, 140, 140, 0.35)'}}>
                <Row gutter={16}>
                <Col xs={{ span: 12, offset: 0 }} lg={{ span: 16}}>
                    <Button key ={"b1"} onClick={onOpenMenu}  icon={<Icon type='BarsOutlined' />}/>
                </Col>
                <Col xs={{ span: 3, offset: 0 }} lg={{ span: 6 }}>
                    <Button onClick={showDrawer}  icon={<PlusOutlined />} type="primary">Nuevo Aviso</Button>
                </Col>
                </Row>    
            </Header>   
            <Card size="small" style={{ textAlign: 'Left', border:'none' }}   >
               
                <ListaSolicitudes mantenimientodata={mantenimientodata}   />
                <ModalMantenimiento OpenMant={OpenMant} Loading={Loading} handleSave={handleSave} handleCancelMant={onClose} Repercusiones={Repercusiones} ClaseAviso={ClaseAviso} Ubicaciones={Ubicaciones} locale={locale}/>
                <ModalMenu OpenMenu={OpenMenu} Loading={Loading}  handleCancelMenu={onCloseMenu} />
               
            </Card>
        </div>
       
    );
}

export default Mantenimiento