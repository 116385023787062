import {useState,useEffect}  from 'react'
import {useLazyQuery} from '@apollo/client'
import {GetUbicaciones,GetRepercusiones,GetClaseAviso} from "../Models/query/general/General"


export const useAllUbicaciones = () =>{
 
    const [getUbicacionesAlldata] = useLazyQuery(GetUbicaciones);
    const [UbicacionesAlldata,SetubicacionesAlldata] = useState([])
    const[error,SetError] = useState(null)
    const[isubicacionesLoading,SetIsubicacionesLoading] = useState(true)
    
    useEffect(()=>{
        async function getDataAll(){
            try{  
                const  UbicacionesData =  await getUbicacionesAlldata()
                if(UbicacionesData){
                   SetubicacionesAlldata(UbicacionesData.data.GetUbicaciones)
                } 
            }
            catch(error){
                SetError(error.message)
            }
            SetIsubicacionesLoading(false)

        }
        getDataAll()

    },[getUbicacionesAlldata])

     return{
        UbicacionesAlldata,
        error,
        isubicacionesLoading
     }
}

export const useAllRepercusiones = () =>{
 
    const [getRepercusionesAlldata] = useLazyQuery(GetRepercusiones);
    const [RepercusionesAlldata,SetrepercusionesAlldata] = useState([])
    const[error,SetError] = useState(null)
    const[isRepercusionesLoading,SetIsrepercusionesLoading] = useState(true)
    
    useEffect(()=>{
        async function getDataAll(){
            try{  
                const  RepercusionesData =  await getRepercusionesAlldata()
                if(RepercusionesData){
                   SetrepercusionesAlldata(RepercusionesData.data.GetRepercusiones)
                } 
            }
            catch(error){
                SetError(error.message)
            }
            SetIsrepercusionesLoading(false)

        }
        getDataAll()

    },[getRepercusionesAlldata])

     return{
        RepercusionesAlldata,
        error,
        isRepercusionesLoading
     }
}

export const useAllAviso = () =>{
    
    const [getClaseAvisoAlldata] = useLazyQuery(GetClaseAviso);
    const [ClaseAvisoAlldata,SetclaseAvisoAlldata] = useState([])
    const[error,SetError] = useState(null)
    const[isClaseAvisoLoading,SetIsclaseAvisoLoading] = useState(true)
    
    useEffect(()=>{
        async function getDataAll(){
            try{  
                const  ClaseAvisoData =  await getClaseAvisoAlldata()
                if(ClaseAvisoData){
                   SetclaseAvisoAlldata(ClaseAvisoData.data.GetClaseAviso)
                } 
            }
            catch(error){
                SetError(error.message)
            }
            SetIsclaseAvisoLoading(false)

        }
        getDataAll()

    },[getClaseAvisoAlldata])

     return{
        ClaseAvisoAlldata,
        error,
        isClaseAvisoLoading
     }
}




export default useAllUbicaciones


