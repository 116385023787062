
import {useQuery,useMutation} from '@apollo/client'
import {GetAvisosMant} from "../Models/query/mantencion/mantencion"
import {CrearMantenimiento} from "../Models/mutation/mantencion/Setmantencion"


export const useGetAvisosMant = () =>{
    const result = useQuery(GetAvisosMant);
    return result;
}

export const useCrearMantenimiento = () =>{
 
   const  result = useMutation(CrearMantenimiento,{refetchQueries:[{query:GetAvisosMant}]});                  
   return result;

}


export default useGetAvisosMant


