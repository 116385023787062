import React  from 'react'
import { Button, Form, Input, Space, Row, Col,Select,Drawer,DatePicker,Checkbox} from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
const { TextArea } = Input;
const { Option } = Select;



const ModalMantenimiento = ({ OpenMant, Loading, handleSave, handleCancelMant,Repercusiones,ClaseAviso,Ubicaciones,locale}) => {
  
    const [form11] = Form.useForm();

    const handleFormSubmit = () => {
       form11.validateFields()
			.then((values) => {
				return handleSave(values); 
			})
			.catch((errorInfo) => {
                //  console.log(errorInfo)
            });
        
    };

    const onClose = () => {
       handleCancelMant(false);
    };
    
    const onOk = (values) => {
       // console.log('onOk: ', values);
    };

    const handleChange = (values) => {
       
        if(values==='Z2'){
           form11.setFieldsValue({
                EquipoDetenido:true,
            });
        }
        else{
              form11.setFieldsValue({
                EquipoDetenido:'',
            });
        }
    };

    const Icon = ({ type, ...rest }) => {
        const icons = require(`@ant-design/icons`);
        const Component = icons[type];
        return <Component {...rest} />;
    };

    
    return (
         <Drawer
                    title="Nuevo Aviso"
                    width={window.innerWidth > 900 ? '70%' : '100%'}
                   
                    onClose={onClose}
                    open={OpenMant}
                    bodyStyle={{ paddingBottom: 80 }}
                    extra={
                    <Space>
                        <Button key="1" onClick={onClose}  >Cancel</Button>
                        <Button key="2" onClick={handleFormSubmit}  type="primary" loading={Loading}>
                            Enviar
                        </Button>
                    </Space>
                    }
                >
       
        <InfiniteScroll
            dataLength={50}
            height="90vh"
        >
        <Form form={form11} name="NuevoAviso"   >
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 6 }}>
                <Form.Item 
                    name="FechaFalla"
                    readOnly={true}
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Fecha de falla '  
                        }    
                    ]}
                    hasFeedback  
                    
                >
                    <DatePicker onOk={onOk} inputReadOnly style={{ width: '100%' }} locale={locale}/>
                    
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 6 }}>
                <Form.Item 
                    name="HoraFalla"
                    readOnly={true}
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Horade falla '  
                        }    
                    ]}
                    hasFeedback  
                    
                >
                    <DatePicker   picker={'time'} onOk={onOk} inputReadOnly style={{ width: '100%' }} />
                    
                </Form.Item>
                </Col>

                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 6 }}>
                <Form.Item
                    name="ClaseAviso"
                    rules={[
                        { required: true,message: 'Seleccione ClaseAviso'},
                                
                    ]}
                    hasFeedback
                >
                   <Select placeholder="Clase Aviso"  onChange={handleChange}>
                    {ClaseAviso.map((data) => {
                        return (
                            <Option key={data.codigo} value={data.codigo}>
                                {data.descripcion}
                            </Option>
                        );
                   }) }
                </Select>
                </Form.Item>
              
                </Col>
                 <Col xs={{ span: 24, offset: 0 }} lg={{ span: 6 }}>
                <Form.Item
                    name="UbicacionTecnica"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Ubicacion Tecnica',
                        },
                    ]}
                    hasFeedback
                >
                    <Select placeholder="Ubicacion Tecnica" >
                    {Ubicaciones.map((data) => {
                        return (
                            <Option key={data.codigo} value={data.codigo}>
                                {data.descripcion}
                            </Option>
                        );
                   }) }
                </Select>
                </Form.Item>
                </Col>
                </Row>
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span:12 }}>
                <Form.Item
                    name="DescripcionAviso"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Descripcion Aviso',
                        },
                    ]}
                    hasFeedback
                >
                    <TextArea showCount maxLength={100}  />
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                 <Form.Item
                    name="DetalleAviso"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Detalle Aviso',
                        },
                    ]}
                    hasFeedback
                >
                    <TextArea showCount maxLength={100}  />
                </Form.Item>
                </Col>
                </Row>
                <Row gutter={16}>
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                 <Form.Item
                    name="Supervisor"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Supervisor',
                        },
                    ]}
                    hasFeedback
                >
                     <Input 
                        maxLength={100}
                        prefix={<Icon type='UserOutlined'  className="site-form-item-icon" />}
                        type="text"
                        placeholder="ingrese Supervisor"
                        
                        
                    />
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="Repercusiones"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Ubicacion Tecnica',
                        },
                    ]}
                    hasFeedback
                >
                    <Select placeholder="Repercusiones" >
                    {Repercusiones.map((data) => {
                        return (
                            <Option key={data.codigo} value={data.codigo}>
                                {data.descripcion}
                            </Option>
                        );
                   }) }
                </Select>
                </Form.Item>
                </Col>
                </Row>
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 24 }}>
                 <Form.Item
                    name="EquipoDetenido"
                    valuePropName="checked"
                    rules={[
                        {
                            required: false,
                            message: 'Por favor ingrese Equipo',
                        },
                    ]}
                    hasFeedback
                >
                    <Checkbox  disabled>Equipo Detenido</Checkbox>
                </Form.Item>
                 </Col>
                
                </Row>
                
            </Form>
            </InfiniteScroll>
           
        </Drawer>
    )
}

export default ModalMantenimiento
