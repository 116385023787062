import gql from "graphql-tag";

export const CrearMantenimiento = gql`
mutation CreateClient($fecha_falla:String!,$hora_falla:String!,$clase_aviso:String!,$descripaviso:String!, 
                      $Ubicaciontecnica:String!, $detalleaviso:String!, $supervisorasignado:String!, 
                      $equipoparado:String!, $repercucion:String!,

){
   
  CrearMantenimiento(
    fecha_falla : $fecha_falla,
    hora_falla : $hora_falla,
    clase_aviso : $clase_aviso,
    descripaviso : $descripaviso,
    Ubicaciontecnica : $Ubicaciontecnica
    detalleaviso : $detalleaviso
    supervisorasignado : $supervisorasignado
    equipoparado : $equipoparado
    repercucion :  $repercucion
   
  )
  {
    success
    message
  }
  


    
}`;

