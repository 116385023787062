import React from 'react'
import { List, Avatar, Descriptions,Tag,Card} from 'antd';
//import Highlighter from 'react-highlight-words';
import InfiniteScroll from 'react-infinite-scroll-component';

const ListaSolicitudes = ({mantenimientodata}) => {
  
  
    const Icon = ({ type, ...rest }) => {
        const icons = require(`@ant-design/icons`);
        const Component = icons[type];
        return <Component {...rest} />;
    };


    return (
          
        <InfiniteScroll
            dataLength={mantenimientodata.length}
             height="81vh"
            //endMessage={<Divider plain>Es todo, nada más 🤐 </Divider>}
            style={{
                padding: '0 16px',
                //border: '01px solid rgba(140, 140, 140, 0.35)',
            }}
        >
         <List
        itemLayout="horizontal"
        dataSource={mantenimientodata}
        
        renderItem={item => (
        
        <List.Item>

            <Card  size="small" >
           
            <Card.Meta    style={{padding: '20px 0px' }}
                 avatar={<Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }} icon={<Icon type="SolutionOutlined" key="handlehead" />} />}
                title={'Solicitud Nro:'+item.id_AvisoMantenimiento}
                description ={<Tag color={item.color} >{item.estado}</Tag>}
                />
            <Descriptions style={{padding: '0px 16px' }} size="small" column={{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }} layout="vertical">
            <Descriptions.Item label={<b>Fecha Falla</b>}>{item.fecha_falla}</Descriptions.Item>
            <Descriptions.Item label={<b>Clase Aviso</b>}>{item.descrip_clase_aviso}</Descriptions.Item>
            <Descriptions.Item label={<b>Ubicación.Tec</b>}>{item.descrip_Ubicaciontecnica}</Descriptions.Item>
            <Descriptions.Item label={<b>Supervisor Asignado</b>}>{item.supervisorasignado}</Descriptions.Item>
            <Descriptions.Item label={<b>Estado Equipo</b>}>{item.equipoparado}</Descriptions.Item>
            <Descriptions.Item label={<b>Repercucion</b>}>{item.descripRepercucion}</Descriptions.Item>
             <Descriptions.Item label={<b>Descripcion Aviso</b>}>{item.descripaviso}</Descriptions.Item>
             <Descriptions.Item label={<b>Detalle Aviso</b>}>{item.detalleaviso}</Descriptions.Item>
         
            </Descriptions>
           
            </Card>
             
        </List.Item>
        
        
            )}
        />
      
        </InfiniteScroll>
    )
}

export default ListaSolicitudes
