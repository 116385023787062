import gql from "graphql-tag";

export const GetClaseAviso = gql`
query{
  GetClaseAviso{
        id_ClaseAviso
        codigo
        descripcion
  } 
}`

export const GetUbicaciones = gql`

query{
  
  GetUbicaciones{
      id_ubicaciones
      codigo
      descripcion
  }
}`

export const GetRepercusiones = gql`

query{
   GetRepercusiones
    {
      id_repercusion
      codigo
      descripcion
    }
    
}`

