import gql from "graphql-tag";

export const GetAvisosMant = gql`
query{
   GetAvisosMant{
     id_AvisoMantenimiento
      fecha_falla
      clase_aviso
      descrip_clase_aviso 
      descripaviso 
      Ubicaciontecnica 
      descrip_Ubicaciontecnica
      detalleaviso 
      supervisorasignado 
      equipoparado 
      repercucion 
      descripRepercucion
      estado
      color
  }
}`;



